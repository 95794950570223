@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");

:root {
  font-size: 6.25%;

  --font-family: "Poppins", sans-serif;
  --font-size: 13rem;
  --primary-color: #7e2ecf;

  --form-max-width: 650rem;

  --container-bg-color: white;
  --container-padding: 20rem 30rem;
  --container-border-radius: 10rem;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  background: #f7f7f7;
}

body {
  font-family: var(--font-family);
  font-size: var(--font-size);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  font-family: var(--font-family);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.montserrat {
  font-family: "Montserrat", sans-serif;
}

.rw-widget svg {
  color: var(--primary-color);
}

.rw-widget > div {
  transition: 0.25s !important;
}

.rw-state-focus > div:first-of-type {
  border-color: var(--primary-color);
  box-shadow: 0 0 1px 0 var(--primary-color) !important;
}

.rw-list-option.rw-state-selected,
.rw-cell.rw-state-selected {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.Toastify__toast-body {
  font-family: var(--font-family);
}
a {
  text-decoration: none;
}
.dropbtn {
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.arrow-margin {
  margin-left: 10px;
}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  right: 0;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: transparent;
}

.table .showWidth:first-child {
  margin-right: 24px !important;
}

.menu .dropdown-content {
  min-width: 20px !important;
  /* width: 20px !important; */
}


.disabled-icon {
  cursor: not-allowed;
}

.disabled-icon svg {
  pointer-events: none;
  opacity: 0.3;
}

.disabled-day {
  background-color: #ddd;
  cursor: default;
  pointer-events: none;
}